.trade-main{
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 1% 2%;
    grid-gap: 2%;
    
}

.chart{
    background-color: black;
    border-radius: 15px;
    padding: 2%;
}

.token-info{
    text-align: center;
    color: white;
    background-color: black;
    border-radius: 15px;
    padding-top: 10%;

}

.title{
    font-family: punk2;
    font-style: normal;
    font-weight: 200;
    font-size: 1.4rem;
}

.sub-info{

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px,1fr));
    padding: 4%;
    grid-gap: 6%;
    /* margin-bottom: 10%; */
    gap:10px;
    
}

.opt-parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid white;
    min-height: 70px;
    border-radius: 20px;
    padding: 10px;
    justify-content: space-around;
    background-color: #1c1c1c;
}
.opt{
    color: white;
    text-align: left;
    font-weight: 900;
    text-align: center;
    overflow-wrap: anywhere;
    font-size: 0.76rem;
    font-family: punk2;
    
}
.ans{
    text-align: center;
    font-weight: 900;
    font-size: 1.2rem;
    word-break: break-all;
    color: #0059ff;
    font-family: punk2;

    
}

.execution{
    background: black;
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 15px;
    padding: 3% 2%;
    margin-bottom: 3%;
    font-family: punk2;
    /* width: 100%; */
}

.buy-sell{
    display: flex;
    text-align: center;
    border-radius: 15px;
    background: #00000085;
    padding: inherit;
    min-width: 0;
}

.selector-btn,.selector-btn-buy,.selector-btn-sell{
    width: 100%;
    font-family: cyberPUNK;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 32px;
    border-radius: 10px;
    color: #808080;
    padding: inherit;
    cursor: pointer;
}

.selector-btn-buy{
    color: white;
    background-color: #629944;
}
.selector-btn-sell{
    color: white;
    background-color: rgb(224, 76, 76);
}

.buy-sell>input{
    font-family: punk2;
    font-style: normal;
    font-weight: 100;
    font-size: 1rem;
    background-color: transparent;
    border: 0px;
    color:#808080aa;
    border: 1px solid #808080;
    padding:10px;
    border-radius: 10px;
}
.buy-sell>input::placeholder{
    color:#808080aa;

}
.buy-sell>input:focus{
    outline: none;
}
#balance{
    color: #808080;
    text-align: left;
    margin-left: 4px;
    margin-top: 2%;
    font-size: 0.8rem;
}

.confirmation{
    display: flex;
    text-align: center;
    margin-top: 2%;
    font-family: cyberPUNK;
    font-style: normal;
    font-weight: 100;
    font-size: 1rem;
    letter-spacing: 2px;
    
}

.confirmation>div{
    cursor: pointer;
    width: 100%;
    padding: 2%;
    border-radius: 15px;
    font-weight: 900;
    color: white;
    margin: 2%;
    background-color: #1969FF;
}

#heading{
    font-size: 1.8rem;
    font-weight: 900;
    width: 100%;
    font-family: cyberPUNK;

}
#sub-heading{
    font-size: 1rem;
    width: 100%;
    font-family: punk2;
}
.footer-content{
    color: #FFFFFF;
    border-top: 2px solid #828282;
    border-bottom: 2px solid  #828282;
    height: max-content;
    font-weight: normal;
    
}

.footer-content>p{
    font-family: punk2;
}
.links{
    display: flex;
    margin-top: 5%;
    flex-direction: row;
    font-size: 0.5rem;
    justify-content: flex-start;
    margin-bottom: 3%;
    align-items: center;
    
}
.links>a{
    margin-right: 2%;
    margin-left: 2%;
    text-decoration: none;
    color:#FFFFFF;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

@media screen and (max-width:1080px) {
    .trade-main{
        grid-template-columns: 1fr;
        grid-gap: 1%;
        padding: 1% 2%;
    }
    .execution{width: auto;
    padding: 3% 1%;}
    
    .buy-sell{
        padding: 3%;
    }
    div.footer:nth-child(3){
        order: 4;
        text-align: center;
    }
    .links{
        justify-content: center;
    }
    .chart{
        min-height: 350px;
    }
}

@media screen and (min-width:2400px) {
    .trade-main{
        padding: 1% 20%;
    }
}