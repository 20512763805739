nav{
    display: flex;
    flex-direction: row;
    padding: 1% 4%;
    align-items: center;
    background-color: #000000;
    gap:20px
    /* margin-bottom: 4%; */
}

.option{
    color: #828282;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    /* margin-left: 1%; */
    cursor: pointer;
    font-family: cyberPUNK;
    font-size: 0.85rem;
}

.search-bar,.search-bar-m{
    display: flex;
    flex-direction: row;
    position: relative;
}
.search-bar{
    margin-left: auto;
    box-shadow: 0px 4px 0px #000000;

}
.search-bar-m{
    display: none;
}
.search-bar-m>input,.search-bar>input{
    font-family: punk2;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    background-color: #D3D3D3;
    color: black;
    border-radius: 0px 15px 15px 0px;
    border-color: transparent;
    
}
.search-bar-m>input{
    background-color: #000000;
    color: #828282;
    font-family: punk2;
}
.search-bar-m>input,.search-bar>input::placeholder{
    color:#828282;
    font-family: punk2;
    font-size: 0.86rem;
}
.search-bar-m>input,.search-bar>input:focus{
    outline: none;
}

.menu-btn{
    display: none;
}
.search-bar,.connect-btn,.option{
    display: inherit;
}
.connect-btn{
    margin-left: auto;
    color:black;
    font-size: 1rem;
    background-color: #FAFAFA;
    border-radius: 15px;
    padding: 0.2% 1%;
    height: 48px;
    align-items: center;
    display: flex;
    gap: 10px;
    cursor: pointer;
    font-family: punk2;
    font-weight: 900;
}
.notif{
    width: 100%;
    justify-content: center;
    position: fixed;
    left: 0;
    z-index: 10;
    margin-top: 8%;
    text-align: center;
    
}
#main-notif-cls,#main-notif{
    background: radial-gradient(65.04% 92.14% at 96.28% 92.63%, rgba(59, 170, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(177.08% 194.24% at -110.38% -98.45%, rgba(255, 171, 0, 1) 0%, rgba(0, 0, 0, 0.5) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #000000;
}
#main-notif{
    animation: fadeIn 2.1s;
}
#main-notif-cls{
    animation: fadeIn-halt 1s;
}
@keyframes fadeIn-halt {
    0%{opacity: 0;}
    100%{opacity: 1;}
}
@keyframes fadeIn {
    0%,100%{opacity: 0;}
    50%{opacity: 1;}
    
}

@media screen and (max-width:1424px) {
    nav{
        padding: 4% 2%;
        justify-content: space-between;
    }
    .menu-btn{
        display: initial;
        
    }
    #main-notif-cls,#main-notif{
        margin:2%
    }
    .search-bar,.connect-btn,.option{
        display: none;
    }
    .search-bar-m{
        display: flex;
        flex-direction: row;
        padding: 2% 5%;
        flex-basis: 0px;
        flex-grow: 1;
    }
    .notif{
        margin-top: 20%;
    }
}

@media screen and (max-width:900px) {
    .notif{
        flex-direction: column;
        text-align: center;
    }
    #main-notif-cls,#main-notif{
        margin:2%;

    }
}

@media screen and (max-width:1424px) {
    .search-bar-m{
        padding: 2%;
    }
}

@media screen and (max-width:400px) {
    .notif{
        padding-top: 0%;
        
    }
    #main-notif-cls,#main-notif{
        margin:2%;
        max-height: 500px;
        overflow-y: scroll;
        
    }


}


@font-face {
    font-family: CyberPUNK;
    src: url("./Stormlight.otf");
}

@font-face {
    font-family: PUNK2;
    src: url("./OriginTech\ personal\ use.otf");
}